/* Modal container */
.modal {
  display: flex; /* Hide the modal by default */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
}

/* Modal content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: 15% auto; /* Center the modal vertically and horizontally */
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #888;
  width: clamp(300px, 500px, 500px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}
.modal-content .loader {
  margin-top: 70px;
}
.modal-content .loader::before {
  border: 5px solid #2c2f50;
}

button.attend-contest-btn {
  background-color: #2c2f50;
  margin-top: 25px;
  color: #fefefe;
  padding: 10px 20px;
  border-radius: 10px;
  position: relative;
  font-size: 80%;
  /* height: 20px; */
}
button.attend-contest-btn:nth-child(3) {
  background-color: #fff;
  border: 2px solid #2c2f50;
  color: #2c2f50;
}
/* Close button */
.close {
  color: #aaa;
  position: absolute;
  right: 25px;
  top: 15px;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
