.profileContainer-progress {
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
}
.profile-progress {
  width: 400px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
}

.status-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.status-column {
  display: flex;
  flex-direction: column;
}

.status-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 14px;
}
.status-item > img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.status-item.in-progress {
  background: #fff3cd;
  color: #856404;
}

.progress-bar-container {
  position: relative;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-end;
}

.progress-bar {
  width: 82%;
  height: 10px;
  background: #e9ecef;
  border-radius: 5px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background: #007bff;
}

.progress-percentage {
  position: absolute;
  top: -10px;
  left: 31%;
  transform: translateX(-50%);
  font-size: 49px;
}
.circleProgerss-percentage {
  border: 4px solid rgba(0, 67, 206, 1);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circleProgerss-percentage > div {
  background: rgba(0, 67, 206, 1);
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.payment-button {
  padding: 5px 10px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  bottom: 60px;
}
.payment-button > svg {
  position: absolute;
  right: 5px;
  bottom: -17px;
  scale: 0.5;
}
.payment-button:hover {
  background: #0056b3;
}
